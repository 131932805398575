<template>
    <div>
    </div>
</template>

<script>
import geoviewer from "./store/index.js"
export default {
    created() {
        if (!this.$store.hasModule("geoviewer"))
            this.$store.registerModule("geoviewer", geoviewer)
    }
}
</script>